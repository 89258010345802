import {withPrefix} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Layout} from '../../../components/layout';

export default () => (
    <Layout
        title="Privacy, AVG en gegevensverwerkers"
        description="Bij Label305 zetten we de gebruiker voorop. Daarom vinden we de privacy van eindgebruikers van onze softwareproducten extra belangrijk."
        ogImage="privacy.png"
        translatedPagePath="/en/terms-and-conditions/privacy/">
        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>Privacy&shy;verklaring</h2>
                        <div className="display__number">P</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Bij Label305 zetten we de gebruiker voorop. Daarom vinden we de privacy van{' '}
                            eind&shy;gebruikers extra belangrijk.
                        </p>
                        <p>
                            In onze privacy&shy;verklaring vind je een{' '}
                            <span className="highlight">duidelijke uitleg</span> over hoe wij omgaan met zowel{' '}
                            persoons&shy;gegevens van onze opdrachtgevers als die van hun eind&shy;gebruikers.
                        </p>
                        <p>
                            Privacybewust hè&mdash;waarom heeft deze website dan geen cookie&shy;melding? Nou, deze{' '}
                            website plaatst <span className="highlight">geen</span> cookies en houdt geen{' '}
                            bezoekers&shy;statistieken bij. Dit vinden wij gewoon niet nodig.
                        </p>
                    </div>

                    <div className=" content__buttons content__buttons--50">
                        <Button
                            direct
                            url={withPrefix('/files/privacyverklaring.pdf')}
                            name="Privacyverklaring (PDF)"
                            className="button--download"
                            title="Download onze Privacyverklaring als PDF"
                        />
                    </div>
                </div>
            </article>

            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>Privacy&shy;wetgeving</h2>
                        <div className="display__number">AVG</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            De Europese privacywetgeving (AVG, of GDPR in het Engels) geeft bedrijven die{' '}
                            persoons&shy;gegevens verzamelen de plicht inzichtelijk te hebben welke externe partijen met{' '}
                            deze gegevens werken.
                        </p>
                        <p>
                            In onze <span className="highlight">verwerkers&shy;overeenkomst</span> spreken we goed af{' '}
                            met onze opdracht&shy;gevers hoe wij precies omgaan met persoons&shy;gegevens uit een{' '}
                            software&shy;product dat wij voor hen ont&shy;wikkelen. Deze overeenkomst hebben onze{' '}
                            opdracht&shy;gevers nodig om te voldoen aan de privacy&shy;wetgeving.
                        </p>
                        <p>
                            Hieronder is ook een lijst van al onze <span className="highlight">(sub)verwerkers</span>{' '}
                            beschikbaar. Onze &#8216;verwerkers&#8217; zijn externe partijen waar wij enkel{' '}
                            project&shy;bestanden en gegevens over onze opdrachtgevers laten verwerken.{' '}
                            &#8216;Subverwerkers&#8217; zijn externe partijen waar wij ook gegevens uit{' '}
                            software&shy;producten van opdracht&shy;gevers laten verwerken. Deze gegevens bevatten soms{' '}
                            ook persoons&shy;gegevens van eind&shy;gebruikers.
                        </p>
                    </div>

                    <div className=" content__buttons content__buttons--50">
                        <Button
                            direct
                            url={withPrefix('/files/verwerkersovereenkomst.pdf')}
                            name="Verwerkersovereenkomst (PDF)"
                            className="button--download"
                            title="Download onze Verwerkersovereenkomst als PDF"
                        />
                        <Button
                            direct
                            url={withPrefix('/files/verwerkers.pdf')}
                            name="Lijst met onze (sub)verwerkers (PDF)"
                            className="button--download"
                            title="Download een lijst van onze (sub)verwerkers als PDF"
                        />
                    </div>
                </div>
            </article>
        </section>
    </Layout>
);
